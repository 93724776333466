import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

export default function NotFound() {
  return (
    <Layout>
      <SEO />
      Page Not Found
    </Layout>
  );
}
